<script setup>
// Define reactive variables using ref
const diamonds = ref(0);
const followers = ref(0);
const liveshows = ref(0);
const views = ref(0);
const result = ref('');

// Function to calculate earnings
function calculateEarnings() {
    if (views.value && followers.value && liveshows.value) {
        const diamondsPerViewer = 3; // Average diamonds per viewer based on high and low-end shows
        const earningsPerDiamond = 0.005;
        const showLengthFactor = liveshows.value / 2; // Assuming 2 hours as the baseline

        // Calculate estimated diamonds and earnings
        const estimatedDiamonds = views.value * diamondsPerViewer * showLengthFactor;
        const estimatedEarnings = (estimatedDiamonds * earningsPerDiamond).toFixed(2);

        result.value = `Estimated Potential Profit: $${estimatedEarnings} (Results may vary)`;
    } else {
        result.value = "Please fill in all fields.";
    }
}
</script>
<template>
    <div>
        <div class="main m-2a alg:m-10 alg:p-16 p-5a lg:min-w-[22rem] w-full aw-[30rem] mx-auto ah-full">
            <div
                class="calculator py- w-fit bg-zinc-950 items-center flex flex-col justify-center mx-auto p-5 rounded-sm outline outline-[#00e5dee6] shadow-md shadow-[#00f2ea]/40">
                <h2 class="font-bold md:text-2xl text-2xl text-center mb-5 mt-5">{{ $t('calculatortitle') }}</h2>

                <div class="lg:flex md:gap-8 text-center">
                    <label for="viewers" class="min-w-fit my-auto">{{ $t('calculator1') }}</label>
                    <input type="number" v-model.number="views" min="0" id="viewers"
                        placeholder="Enter number of viewers" class="w-[10rem]">
                </div>

                <div class="lg:flex md:gap-5 text-center">
                    <label for="followers" class="min-w-fit my-auto">{{ $t('calculator2') }}</label>
                    <input type="number" v-model.number="followers" min="0" id="followers"
                        placeholder="Enter number of followers" class="w-[10rem]">
                </div>

                <div class="lg:flex md:gap-1 text-center">
                    <label for="showLength" class="min-w-fit my-auto">{{ $t('calculator3') }}</label>
                    <input type="number" v-model.number="liveshows" min="0" id="showLength"
                        placeholder="Enter length of live show" class="w-[10rem]">
                </div>
                <button @click="calculateEarnings" class="flex mx-auto m-3">{{ $t('calculator4') }}</button>

                <div id="result" class="text-center max-w-[20rem]">{{ result }}</div>
                <!-- <div v-if="error">{{ error }}</div> -->
            </div>
        </div>
    </div>
</template>
<style scoped>
input {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgb(223, 223, 223);
    color: black;
    font-size: 16px;
}

button {
    padding: 10px 20px;
    background-color: #ff0050;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

#result {
    margin-top: 20px;
    font-size: 18px;
    color: #00f2ea;
}
</style>